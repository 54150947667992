<template>
  <div>
    <b-alert
      v-if="ability.can('update', 'Referral')"
      variant="primary"
      show
    >
      <div class="alert-body">
        <b-row>
          <b-col
            cols="12"
            sm="8"
            align-self="center"
          >
            <p class="ml-1">
              {{ $t('referral.create_referral_intro') }} <br>
              <span>
                <feather-icon
                  icon="InfoIcon"
                  class="mr-50"
                />
                <em> {{ $t('referral.referral_info') }}</em>
              </span>
            </p>
          </b-col>
          <b-col
            cols="12"
            sm="4"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="primary"
              @click="toggleModal()"
            >
              <feather-icon
                icon="UsersIcon"
                class="mr-50"
              />
              <span class="align-middle">{{ $t('referral.create_referral') }}</span>
            </b-button>
          </b-col>
        </b-row>
      </div>

    </b-alert>
    <!-- Modal Form -->
    <b-modal
      id="modal-form"
      ref="modal-form"
      v-model="showModal"
      centered
      hide-footer
      :static="true"
    >
      <!-- Modal Form Header -->
      <template #modal-header>
        <b-container
          fluid
        >
          <b-button
            class="close btn-icon"
            variant="outline-secondary"
            @click="toggleModal()"
          >
            <feather-icon
              icon="XIcon"
              size="26"
            />
          </b-button>
          <b-media
            no-body
          >
            <b-media-aside>
              <b-avatar
                rounded
                variant="primary"
                size="24"
              >
                <feather-icon
                  icon="UsersIcon"
                  size="18"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="align-self-center">
              <span>{{ $t('referral.create_referral') }}</span>
            </b-media-body>
          </b-media>
        </b-container>
      </template>
      <!-- Modal Form Body -->
      <!--Form-->
      <b-container
        fluid
      >
        <referrals-register-form
          :register-button-text="$t('referral.create')"
          @user-managed-created="handleUserManagedCreated()"
        />
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import ReferralsRegisterForm from '@/views/apps/referral/referrals/ReferralsRegisterForm.vue'

import {
  BRow,
  BCol,
  BModal,
  BButton,
  BContainer,
  BAvatar,
  BMedia,
  BMediaBody,
  BMediaAside,
  BAlert,
} from 'bootstrap-vue'

import Ripple from 'vue-ripple-directive'
import { heightFade } from '@core/directives/animations'

import { defineAbilityForCurrentUser } from '@/libs/acl/defineAbility'
import { useReferral } from '@/views/apps/referral/useReferral'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BContainer,
    BAvatar,
    BMedia,
    BMediaBody,
    BMediaAside,
    BAlert,

    ReferralsRegisterForm,
  },
  directives: {
    Ripple,
    'height-fade': heightFade,
  },

  data() {
    return {
      showModal: false,
    }
  },

  methods: {
    toggleModal() {
      this.showModal = !this.showModal
    },
    handleUserManagedCreated() {
      this.toggleModal()
      this.fetchReferrals()
    },
  },

  setup() {
    const ability = defineAbilityForCurrentUser()
    const { fetchReferrals } = useReferral()
    return {
      ability,
      fetchReferrals,
    }
  },
}
</script>
