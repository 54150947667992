import { postStatuses, serviceOptions } from '@/mixins/options'
import i18n from '@/libs/i18n'

export const formatTimeOfStay = {
  methods: {
    formatTimeOfStay(timeInMinutes) {
      const hours = Math.floor(timeInMinutes / 60)
      const minutes = timeInMinutes % 60
      if (!hours) {
        return `${minutes} min`
      }
      return `${hours} hrs : ${minutes} min`
    },
  },
}

export const getPostStatusProperty = {
  mixins: [postStatuses],
  methods: {
    getPostStatusProperty(slug, property) {
      const postStatus = this.postStatuses.filter(status => status.slug === slug)
      return postStatus[0][property]
    },
  },
}

export const getUserAvatarText = {
  methods: {
    getUserAvatarText(UserName) {
      return UserName?UserName.substring(0, 1).toUpperCase():''
    },
  },
}

export const isMobile = {
  methods: {
    isMobile() {
      return window.innerWidth <= 767
    },
  },
}

export const numbersOnly = {
  methods: {
    numbersOnly(e) {
      const symbol = String.fromCharCode(e.keyCode)
      if (/^[0-9]+$/.test(symbol)) return true
      e.preventDefault()
    },
  },
}
export const getFormattedAddress = {
  methods: {
    getFormattedAddress(address) {
      const addr = [
        address.road,
        address.house_number,
        address.postcode,
        address.locality,
        address.state,
        address.country,
      ]

      if (address.apartment_number) {
        const apartmentNumber = address.apartment_number.replace(/.+/g, 'apt. $&')
        addr.splice(2, 0, apartmentNumber)
      }
      return addr.filter(Boolean).join(', ')
    },
  },
}

export const extendPointTypesAndPurposes = options => {
  // Add icons, tooltips etc
  const result = []
  options.forEach(obj => {
    const type = serviceOptions.categories.find(({ slug }) => slug === obj.slug)
    if (type && type.point_purposes) {
      const res = []
      obj.point_purposes.forEach(fetchedPurpose => {
        const purpose = type.point_purposes.find(({ slug }) => slug === fetchedPurpose.slug)
        if (purpose) {
          res.push({ ...fetchedPurpose, ...purpose })
        }
      })
      type.point_purposes = res
    }
    result.push({ ...obj, ...type })
  })
  return result
}
export const translateApiErrors = errors => {
  Object.keys(errors).forEach(field => {
    const errorMessages = errors[field]
    errorMessages.forEach((message, index) => {
      errorMessages[index] = i18n.t(message)
    })
  })
}

export const findPath = (ob, key) => {
  const path = [];
  const keyExists = (obj) => {
    if (!obj || (typeof obj !== "object" && !Array.isArray(obj))) {
      return false;
    }
    else if (obj.hasOwnProperty(key)) {
      return true;
    }
    else if (Array.isArray(obj)) {
      let parentKey = path.length ? path.pop() : "";

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`);
        const result = keyExists(obj[i], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    }
    else {
      for (const k in obj) {
        path.push(k);
        const result = keyExists(obj[k], key);
        if (result) {
          return result;
        }
        path.pop();
      }
    }
    return false;
  };

  keyExists(ob);

  return path.join(".");
}

export const _ = null
